import React from 'react'
import "./style-footer.css"
import LINKEDIN from "../../assets/images/Social-icons/LINKEDIN - MOHIT SAVALIYA.svg"
import INSTAGRAM from "../../assets/images/Social-icons/INSTAGRAM - MOHIT SAVALIYA.svg"
import FACEBOOK from "../../assets/images/Social-icons/FACEBOOK - MOHIT SAVALIYA.svg"
import WHATSAPP from "../../assets/images/Social-icons/WHATSAPP - MOHIT SAVALIYA.svg"
import LOGO from '../../assets/logo_01.svg'




function Footer() {
  return (
    <>
        <div className="container" style={{marginTop:200}}>
            {/* <div className='Social-Media-layout mb-5'>
                <img src={LINKEDIN} alt="" />
                <img src={INSTAGRAM} alt="" />
                <img src={FACEBOOK} alt="" />
                <img src={WHATSAPP} alt="" />
            </div> */}
            <hr />
            <div className="footer_layout1">
                <div className="footerItems logo-footer">
                    <div className="companyName"><img src={LOGO} alt="" /></div>
                </div>
                {/* <div className="footerItems text-center need-support"><span style={{color:'grey'}}>Need support?</span><a href="mailto:hello@passiepeeps.com">hello@passiepeeps.com</a></div> */}
                <div className="footerItems text-center need-support"><span style={{color:'grey'}}>Need support?</span><a href="mailto:passiepeeps.official@gmail.com">passiepeeps.official@gmail.com</a></div>
                
                <div className="footerItems">
                <div className="DevelopedBy">
                    <span style={{color:'grey'}}>Follow Us</span>
                    <div className='social-icons-layout'>
                        <img src={INSTAGRAM} alt="" />
                        <img src={LINKEDIN} alt="" />
                    </div>
                </div>

                    {/* <div className="MenuLayout">
                        <span>Home</span>
                        <span>About Us</span>
                        <span>Services</span>
                        <span>Our Work</span>
                    </div> */}
                </div>
            </div>
            <hr />
            <div className="footer_layout mb-5">
                <div className="footerItems">
                    <div className="companyName fs14">© 2024 Passiepeeps All Rights Reserved</div>
                </div>
                {/* <div className="footerItems"><a href="mailto:hello@passiepeeps.com">hello@passiepeeps.com</a></div> */}
                
                <div className="footerItems">
                <div className="DevelopedBy fs14">Developed by <a href="https://makeitech.com/" target='_blank'>makeitech</a></div>

                    {/* <div className="MenuLayout">
                        <span>Home</span>
                        <span>About Us</span>
                        <span>Services</span>
                        <span>Our Work</span>
                    </div> */}
                </div>
            </div>

            {/* <div className='footer_layout mb-5'>
                <div className="companyName">© 2024 Passiepeeps All Rights Reserved</div>
                <div className="MenuLayout">
                    <span>Home</span>
                    <span>About Us</span>
                    <span>Services</span>
                    <span>Our Work</span>
                </div>
            </div> */}
        </div>
    </>
  )
}

export default Footer