import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style2.css";

function TestingTestimonials() {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        // centerMode: true, // Enables center mode
        centerPadding: '0', // Removes padding around the center slide
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ],
        afterChange: (current) => {
            document.querySelectorAll('.custom-box').forEach((box, index) => {
                if (index === current + 1) {
                    box.classList.add('center-box');
                } else {
                    box.classList.remove('center-box');
                }
            });
        }
    };


    function DataTest(props){
        return(
            <>
                <div className="Testi-Header-layout">

                    <div className="Testi-Header">
                        {/* <div className="Testi-Header-img">
                            <img src="https://dzinr.co.in/wp-content/uploads/2023/07/3-1.png" width={50} alt="" />
                        </div> */}
                        <div className="Testi-Header-info">
                            <h4>{props.name}</h4>
                            <h6>{props.role}</h6>
                        </div>
                    </div>
                    <div className="Testi-Footer">
                        {props.comment}
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div id="testimonialsLayoutTop" style={{padding:"60px"}}></div>

                <div className="container" style={{position:'relative'}}>
                    {/* <div id="testimonialsLayout"></div> */}
                    <div className="custom-slider">
                        <Slider {...settings}>
                            <div className="custom-box"><DataTest name="Deba Pati" role="Founder and CEO (Ceyone)" comment="Working with Passie Peeps has been an incredible experience. Their professionalism, creativity, and attention to detail made a huge impact on our brand new website. The team was always communicative, reliable, and met every deadline without fail. We’ve seen a noticeable increase in our brand visibility thanks to their work, and we highly recommend them."/></div>
                            <div className="custom-box"><DataTest name="Vishwas Kulkarni" role="Head of design at Ultraviolet" comment="We had an exceptional experience working with Passie Peeps on developing and designing our web application. Their team truly understood our mission to revolutionize sustainable mobility with cutting-edge electric vehicles. Their creativity and strategic thinking helped us effectively communicate our vision of high-performance, eco-friendly transportation on our website."/></div>
                            <div className="custom-box"><DataTest name="Bishal" role="Founder and CEO (Foga)" comment="We had a great experience working with the team of passiepeeps. They really got what we were trying to do with our brand and turned our ideas into developing a great brand identity. Super professional, always on time, and easy to work with. Thanks to them, If you’re looking for a creative team that’s reliable and talented, we highly recommend Passie Peeps."/></div>
                            <div className="custom-box"><DataTest name="Nishant NM" role="Host and Founder of Yaana" comment="Working with passie peeps was a game-changer for Yaana. Their work on our brand identity and podcast thumbnails was spot-on, making a huge impact. Fantastic results and highly recommended!"/></div>
                            <div className="custom-box"><DataTest name="Amrita Das" role="Brand Manager (Tasty Tales)" comment="Thanks to passie peeps for tasty tales amazing social media campaigns their creative touch has really made our product shine. Highly recommend their services!"/></div>
                        </Slider>
                    </div>
                </div>

     
        </>
    );
}

export default TestingTestimonials;
