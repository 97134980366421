import React from 'react'
import "./style.css"
import PenLine from '../../../assets/images/PenLine.svg'
import connector from '../../../assets/images/connector.svg'

function Session4(){

    return(
        <>
            <div id='Session4Layout' style={{padding:40}}></div>
            <div className='Session4Layout py-5'>
                <div className="container Session4LayoutChild">
                    <div className='title'>
                        <div className='text'>Highkey Creative, Zero Worries</div>
                        <img src={PenLine} alt="" />
                    </div>
                    <div className='cardLayout'>
                        <div className="row">
                            <div className="col-xl mt-3">
                                <div className="Session4Card-border-wrap">
                                    <div className="Session4Card-Module">
                                        <div className='Session4num'>1</div>
                                        <div className='title'>We Listen <br />& Align:</div>
                                        <div className='description'>We're all ears, syncing with your brand's vibe and make sure we're always on the same wavelength.</div>
                                    </div>
                                    <div className="connector-div">
                                        <img src={connector} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl mt-3">
                                <div className="Session4Card-border-wrap">
                                    <div className="Session4Card-Module">
                                        <div className='Session4num'>2</div>
                                        <div className='title'>We Research <br />& Analyze:</div>
                                        <div className='description'>We're like detectives, digging deep into the market to uncover hidden gems that'll boost your brand's game.</div>
                                    </div>
                                    <div className="connector-div">
                                        <img src={connector} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl mt-3">
                                <div className="Session4Card-border-wrap">
                                    <div className="Session4Card-Module">
                                        <div className='Session4num'>3</div>
                                        <div className='title'>We Ideate <br />& Strategize:</div>
                                        <div className='description'>Our creative juices are flowing, brainstorming ideas that'll make your brand shine brighter than a disco ball.</div>
                                    </div>
                                    <div className="connector-div">
                                        <img src={connector} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl mt-3">
                                <div className="Session4Card-border-wrap">
                                    <div className="Session4Card-Module">
                                        <div className='Session4num'>4</div>
                                        <div className='title'>We Execute <br />with Precision:</div>
                                        <div className='description'>We passionate creators, fine-tune every detail to ensure the details perfectly convey the brand message</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Session4