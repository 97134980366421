import React, { useState } from 'react';
import axios from 'axios';
import "./style.css";
import { useModal } from '../../Components/Modals/ModalContext';


function ContactUs() {
  
  const { showModal,hideModal } = useModal();

  // State to manage form data
  const [formData, setFormData] = useState({
    fullName: '',
    mobileNumber: '',
    emailAddress: '',
    websiteLink: '',

  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload

    
    // console.log(document.getElementById('SuccessModal'));


    try {
      const response = await axios.post('https://passiepeeps.com/api/contact-us-api.php', formData);
      console.log('Form submitted successfully:', response.data);
      if(response.data.status=="success"){

        showModal();

        // window.location.reload();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }

    setTimeout(function(){
      hideModal();
    },5000)
  };

  return (
    <>
      <div id='contact-us'></div>
      <div className="container contactLayout">
        <div className="contactLayout-module-border-wrap">
          <div className="contactLayout-module">
            <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl">
                  <input
                    type="text"
                    className='custom-input my-2'
                    name="fullName"
                    placeholder='Full Name'
                    value={formData.fullName}
                    onChange={handleInputChange}
                  />

                  <input
                    type="text"
                    className='custom-input my-2'
                    name="mobileNumber"
                    placeholder='Mobile Number'
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                  />
                  
                </div>
                <div className="col-xl">
                  <input
                    type="email"
                    className='custom-input my-2'
                    name="emailAddress"
                    placeholder='Email Address'
                    value={formData.emailAddress}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    className='custom-input my-2'
                    name="websiteLink"
                    placeholder='Website Link'
                    value={formData.websiteLink}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <button type="submit" className='contactUsBtn my-2 PassiePeepsBtn'>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;

// import React, { useState } from 'react';
// import "./style.css";

// function ContactUs() {
//   const [formData, setFormData] = useState({
//     fullName: '',
//     mobileNumber: '',
//     emailAddress: '',
//     websiteLink: ''
//   });

//   const [errors, setErrors] = useState({});

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     setErrors({ ...errors, [name]: '' }); // Clear error for the updated field
//   };

//   const validateForm = () => {
//     let formErrors = {};
//     if (!formData.fullName) formErrors.fullName = 'Full Name is required';
//     if (!formData.mobileNumber) formErrors.mobileNumber = 'Mobile Number is required';
//     if (!formData.emailAddress) formErrors.emailAddress = 'Email Address is required';
//     return formErrors;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length > 0) {
//       setErrors(formErrors);
//       return;
//     }

//     // Here you can add Axios post call for submitting formData to an API
//     console.log('Form data submitted successfully:', formData);
//   };

//   return (
//     <>
//       <div id='contact-us'></div>
//       <div className="container contactLayout">
//         <div className="contactLayout-module-border-wrap">
//           <div className="contactLayout-module">
//             <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl">
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.fullName ? 'shake' : ''}`}
//                     name="fullName"
//                     placeholder='Full Name'
//                     value={formData.fullName}
//                     onChange={handleInputChange}
//                   />
//                   {errors.fullName && <div className="error">{errors.fullName}</div>}
                  
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.mobileNumber ? 'shake' : ''}`}
//                     name="mobileNumber"
//                     placeholder='Mobile Number'
//                     value={formData.mobileNumber}
//                     onChange={handleInputChange}
//                   />
//                   {errors.mobileNumber && <div className="error">{errors.mobileNumber}</div>}
//                 </div>
                
//                 <div className="col-xl">
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.emailAddress ? 'shake' : ''}`}
//                     name="emailAddress"
//                     placeholder='Email Address'
//                     value={formData.emailAddress}
//                     onChange={handleInputChange}
//                   />
//                   {errors.emailAddress && <div className="error">{errors.emailAddress}</div>}
                  
//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="websiteLink"
//                     placeholder='Website Link'
//                     value={formData.websiteLink}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
              
//               <div style={{ marginTop: "10px" }}>
//                 <button type="submit" className='contactUsBtn my-2'>Submit</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ContactUs;


// import React, { useState } from 'react';
// import "./style.css"; // Add shake animation styles here

// function ContactUs() {
//   // State to manage form data and errors
//   const [formData, setFormData] = useState({
//     fullName: '',
//     mobileNumber: '',
//     emailAddress: '',
//     websiteLink: ''
//   });

//   const [errors, setErrors] = useState({});

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     setErrors({ ...errors, [name]: '' }); // Clear error message for the field once input is updated
//   };

//   // Form validation
//   const validateForm = () => {
//     let formErrors = {};
//     if (!formData.fullName) formErrors.fullName = 'Full Name is required';
//     if (!formData.mobileNumber) formErrors.mobileNumber = 'Mobile Number is required';
//     if (!formData.emailAddress) formErrors.emailAddress = 'Email Address is required';
//     return formErrors;
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
    
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length > 0) {
//       setErrors(formErrors);
//       return;
//     }

//     // Form submission logic here (e.g., Axios POST request)
//     console.log('Form submitted:', formData);
//   };

//   return (
//     <>
//       <div id='contact-us'></div>
//       <div className="container contactLayout">
//         <div className="contactLayout-module-border-wrap">
//           <div className="contactLayout-module">
//             <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl">
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.fullName ? 'shake' : ''}`}
//                     name="fullName"
//                     placeholder='Full Name'
//                     value={formData.fullName}
//                     onChange={handleInputChange}
//                   />
//                   {errors.fullName && <div className="error">{errors.fullName}</div>}
                  
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.mobileNumber ? 'shake' : ''}`}
//                     name="mobileNumber"
//                     placeholder='Mobile Number'
//                     value={formData.mobileNumber}
//                     onChange={handleInputChange}
//                   />
//                   {errors.mobileNumber && <div className="error">{errors.mobileNumber}</div>}
//                 </div>
//                 <div className="col-xl">
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.emailAddress ? 'shake' : ''}`}
//                     name="emailAddress"
//                     placeholder='Email Address'
//                     value={formData.emailAddress}
//                     onChange={handleInputChange}
//                   />
//                   {errors.emailAddress && <div className="error">{errors.emailAddress}</div>}
                  
//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="websiteLink"
//                     placeholder='Website Link'
//                     value={formData.websiteLink}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div style={{marginTop:"10px"}}>
//                 <button type="submit" className='contactUsBtn my-2'>Submit</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default ContactUs;


// import React, { useState } from 'react';
// import axios from 'axios';
// import "./style.css"; // Make sure you add the CSS for shake animation here

// function ContactUs() {
//   // State to manage form data and errors
//   const [formData, setFormData] = useState({
//     fullName: '',
//     mobileNumber: '',
//     requirement: '',
//     vision: '',
//     emailAddress: '',
//     websiteLink: '',
//     budget: '',
//     referral: ''
//   });

//   const [errors, setErrors] = useState({});

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     setErrors({ ...errors, [name]: '' }); // Clear error message for the field once input is updated
//   };

//   // Form validation
//   const validateForm = () => {
//     let formErrors = {};
//     if (!formData.fullName) formErrors.fullName = 'Full Name is required';
//     if (!formData.mobileNumber) formErrors.mobileNumber = 'Mobile Number is required';
//     if (!formData.requirement) formErrors.requirement = 'Requirement is required';
//     if (!formData.vision) formErrors.vision = 'Vision is required';
//     if (!formData.emailAddress) formErrors.emailAddress = 'Email Address is required';
//     if (!formData.budget) formErrors.budget = 'Budget is required';
//     if (!formData.referral) formErrors.referral = 'Referral source is required';
//     return formErrors;
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length > 0) {
//       setErrors(formErrors);
//       return;
//     }

//     try {
//       const response = await axios.post('YOUR_API_ENDPOINT', formData);
//       console.log('Form submitted successfully:', response.data);
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   return (
//     <>
//       <div id='contact-us'></div>
//       <div className="container contactLayout">
//         <div className="contactLayout-module-border-wrap">
//           <div className="contactLayout-module">
//             <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl">
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.fullName ? 'shake' : ''}`}
//                     name="fullName"
//                     placeholder='Full Name'
//                     value={formData.fullName}
//                     onChange={handleInputChange}
//                   />
//                   {errors.fullName && <div className="error">{errors.fullName}</div>}
                  
//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.mobileNumber ? 'shake' : ''}`}
//                     name="mobileNumber"
//                     placeholder='Mobile Number'
//                     value={formData.mobileNumber}
//                     onChange={handleInputChange}
//                   />
//                   {errors.mobileNumber && <div className="error">{errors.mobileNumber}</div>}
                  
//                   <select
//                     className={`custom-select my-2 ${errors.requirement ? 'shake' : ''}`}
//                     name="requirement"
//                     value={formData.requirement}
//                     onChange={handleInputChange}
//                   >
//                     <option value="" disabled selected>What is your requirement?</option>
//                     <option value="Option 1">Option 1</option>
//                     <option value="Option 2">Option 2</option>
//                     <option value="Option 3">Option 3</option>
//                     <option value="Option 4">Option 4</option>
//                   </select>
//                   {errors.requirement && <div className="error">{errors.requirement}</div>}

//                   <input
//                     type="text"
//                     className={`custom-input my-2 ${errors.vision ? 'shake' : ''}`}
//                     name="vision"
//                     placeholder='What is your vision about your business?'
//                     value={formData.vision}
//                     onChange={handleInputChange}
//                   />
//                   {errors.vision && <div className="error">{errors.vision}</div>}
//                 </div>
//                 <div className="col-xl">
//                   <input
//                     type="email"
//                     className={`custom-input my-2 ${errors.emailAddress ? 'shake' : ''}`}
//                     name="emailAddress"
//                     placeholder='Email Address'
//                     value={formData.emailAddress}
//                     onChange={handleInputChange}
//                   />
//                   {errors.emailAddress && <div className="error">{errors.emailAddress}</div>}

//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="websiteLink"
//                     placeholder='Website Link'
//                     value={formData.websiteLink}
//                     onChange={handleInputChange}
//                   />

//                   <select
//                     className={`custom-select my-2 ${errors.budget ? 'shake' : ''}`}
//                     name="budget"
//                     value={formData.budget}
//                     onChange={handleInputChange}
//                   >
//                     <option value="" disabled selected>What is your budget?</option>
//                     <option value="Option 1">Option 1</option>
//                     <option value="Option 2">Option 2</option>
//                     <option value="Option 3">Option 3</option>
//                     <option value="Option 4">Option 4</option>
//                   </select>
//                   {errors.budget && <div className="error">{errors.budget}</div>}

//                   <select
//                     className={`custom-select my-2 ${errors.referral ? 'shake' : ''}`}
//                     name="referral"
//                     value={formData.referral}
//                     onChange={handleInputChange}
//                   >
//                     <option value="" disabled selected>How did you get to know about us?</option>
//                     <option value="Option 1">Option 1</option>
//                     <option value="Option 2">Option 2</option>
//                     <option value="Option 3">Option 3</option>
//                     <option value="Option 4">Option 4</option>
//                   </select>
//                   {errors.referral && <div className="error">{errors.referral}</div>}
//                 </div>
//               </div>
//               <div>
//                 <button type="submit" className='contactUsBtn my-2'>Submit</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ContactUs;


// import React, { useState } from 'react';
// import axios from 'axios';
// import "./style.css";

// function ContactUs() {
//   // State to manage form data
//   const [formData, setFormData] = useState({
//     fullName: '',
//     mobileNumber: '',
//     requirement: '',
//     vision: '',
//     emailAddress: '',
//     websiteLink: '',
//     budget: '',
//     referral: ''
//   });

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault(); // Prevent form reload
//     try {
//       const response = await axios.post('YOUR_API_ENDPOINT', formData);
//       console.log('Form submitted successfully:', response.data);
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   return (
//     <>
//       <div id='contact-us'></div>
//       <div className="container contactLayout">
//         <div className="contactLayout-module-border-wrap">
//           <div className="contactLayout-module">
//             <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl">
//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="fullName"
//                     placeholder='Full Name'
//                     value={formData.fullName}
//                     onChange={handleInputChange}
//                   />
//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="mobileNumber"
//                     placeholder='Mobile Number'
//                     value={formData.mobileNumber}
//                     onChange={handleInputChange}
//                   />
//                   <select
//                     className='custom-select my-2'
//                     name="requirement"
//                     value={formData.requirement}
//                     onChange={handleInputChange}
//                   >
//                     <option value="" disabled selected>What is your requirement?</option>
//                     <option value="Option 1">Option 1</option>
//                     <option value="Option 2">Option 2</option>
//                     <option value="Option 3">Option 3</option>
//                     <option value="Option 4">Option 4</option>
//                   </select>
//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="vision"
//                     placeholder='What is your vision about your business?'
//                     value={formData.vision}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="col-xl">
//                   <input
//                     type="email"
//                     className='custom-input my-2'
//                     name="emailAddress"
//                     placeholder='Email Address'
//                     value={formData.emailAddress}
//                     onChange={handleInputChange}
//                   />
//                   <input
//                     type="text"
//                     className='custom-input my-2'
//                     name="websiteLink"
//                     placeholder='Website Link'
//                     value={formData.websiteLink}
//                     onChange={handleInputChange}
//                   />
//                   <select
//                     className='custom-select my-2'
//                     name="budget"
//                     value={formData.budget}
//                     onChange={handleInputChange}
//                   >
//                     <option value="" disabled selected>What is your budget?</option>
//                     <option value="Option 1">Option 1</option>
//                     <option value="Option 2">Option 2</option>
//                     <option value="Option 3">Option 3</option>
//                     <option value="Option 4">Option 4</option>
//                   </select>
//                   <select
//                     className='custom-select my-2'
//                     name="referral"
//                     value={formData.referral}
//                     onChange={handleInputChange}
//                   >
//                     <option value="" disabled selected>How did you get to know about us?</option>
//                     <option value="Option 1">Option 1</option>
//                     <option value="Option 2">Option 2</option>
//                     <option value="Option 3">Option 3</option>
//                     <option value="Option 4">Option 4</option>
//                   </select>
//                 </div>
//               </div>
//               <div>
//                 <button type="submit" className='contactUsBtn my-2'>Submit</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ContactUs;


// import React from 'react'
// import "./style.css"

// function ContactUs() {
//   return (
//     <>
//         <div id='contact-us'></div>
//         <div className="container contactLayout">
//             <div className="contactLayout-module-border-wrap">
//                 <div className="contactLayout-module">
//                     <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
//                     <div className="row">
//                         <div className="col-xl">
//                             <input type="text" className='custom-input my-2' placeholder='Full Name' />
//                             <input type="text" className='custom-input my-2' placeholder='Mobile Number' />
//                         </div>
//                         <div className="col-xl">
//                             <input type="text" className='custom-input my-2' placeholder='Email Address' />
//                             <input type="text" className='custom-input my-2' placeholder='Website Link' />
//                         </div>
//                     </div>
//                     <div style={{marginTop:"10px"}}>
//                         <button className='contactUsBtn my-2'>Submit</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
//   )
// }

// export default ContactUs