import React, { useRef, useEffect } from 'react';
import { useModal } from './ModalContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import CloseIcon from "../../../assets/images/closeIcon.svg"

function SuccessModal(props) {
  const modalRef = useRef(null);
  const { isModalVisible, hideModal } = useModal();

  useEffect(() => {
    const modalElement = modalRef.current;

    if (isModalVisible) {
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
    } else {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
    }
  }, [isModalVisible]);

  return (
    <>
        <div class="modal fade" ref={modalRef}  tabindex="-1"   aria-labelledby="CustomModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="CustomModalLabel">{props.title}</h1>
                    <img src={CloseIcon} width={20} data-bs-dismiss="modal" aria-label="Close" alt="" />
                    
                </div>
                <div class="modal-body">
                    {props.body}
                </div>
                <div class="modal-footer">
                    {/* <button type="button" class="btn btn-sm btn-dark" data-bs-dismiss="modal">Close</button> */}
                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default SuccessModal;


{/* <div
className="modal fade"
id="SuccessModal"
tabIndex="-1"
aria-labelledby="SuccessModalLabel"
aria-hidden="true"
ref={modalRef}
>
<div className="modal-dialog">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="SuccessModalLabel">
        Success
      </h5>
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={hideModal}
      ></button>
    </div>
    <div className="modal-body">This is a success message!</div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        onClick={hideModal}
      >
        Close
      </button>
    </div>
  </div>
</div>
</div> */}