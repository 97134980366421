import React from 'react'
import Header from '../Components/Header'
import "./style.css";
import blackground_curtain from '../../assets/blackground_curtain.svg'
import HeroSession from './HeroSession/HeroSession';
import PPStore from './PPStore/PPStore';
import Session3 from './Session3/Session3';
import Session4 from './Session4/Session4';
import CTASession from './CTASession/CTASession';
import ContactUs from './ContactUsSession/ContactUs';
import Footer from '../Components/Footer';
import AnimatedCursor from "react-animated-cursor"
import Testimonials from './Testimonials/Testimonials';
import TestingTestimonials from './Testimonials/TestingTestimonials';
import { SlideTabsExample } from './BottomMenu/SlideTabsExample';
import CustomModal from '../Components/Modals/CustomModal';
import SuccessModal from '../Components/Modals/SuccessModal';
import MarqueeCompany from '../Components/MarqueeCompany/MarqueeCompany';
import Marquee from '../Components/MarqueeCompanyComponents/marquee';
import HeroSessionNew from './HeroSessionNew/HeroSessionNew';
// import { Helmet } from 'react-helmet';


function LandingPage() {
  return (
    <>
      {/* <Helmet>
        <title>Passiepeeps: Creative + Branding + Content</title>
        <meta name="description" content="Creative, branding and content company who understand your brand through dive deep into your brand's essence to create compelling stories and strategic solutions that resonate. Let us help you speak loud and clear with custom-made branding and content." />
        <meta name="keywords" content="Creative, Branding, Content, Design" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="My Page Title" />
        <meta property="og:description" content="This is a description of my page" />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="My Page Title" />
        <meta name="twitter:description" content="This is a description of my page" />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      <AnimatedCursor showSystemCursor={true} 
                    innerSize={8}
                    outerSize={8}
                    // color='181, 193, 11'
                    outerAlpha={0.2}
                    innerScale={0.7}
                    outerScale={5}
                    outerStyle={{
                      border: '3px solid rgba(181, 193, 11, 1)', // Add stroke effect
                      backgroundColor: 'rgba(181, 193, 11, 0.1)'
                    }}
                    innerStyle={{
                      border: '4px solid rgba(181, 193, 11, 1)',
                      backgroundColor: 'rgba(181, 193, 11, 1)', // Adjust color as needed
                    }}
      />
        {/* <SlideTabsExample/> */}
   
        <Header/>
        {/* <HeroSessionNew/> */}
        <HeroSession/>
        {/* <MarqueeCompany/> */}
        <Marquee/>
        <PPStore/>
        <Session3/>
        <Session4/>
        <CTASession/>
        {/* <Testimonials/> */}
        <TestingTestimonials/>
        <ContactUs/>
        <Footer/>
        <SuccessModal title="Contact Us" body="Thank you for submitting your information!"/>
        <CustomModal id="MaintenanceModal" title="Our Work" body="Under maintenance"/>
        <CustomModal id="SuccessModal" title="Contact Us" body="Thank you for submitting your information!"/>

        <div className="heroSessionBackground3">
            <img src={blackground_curtain} alt="" />
        </div>
    </>
  )
}

export default LandingPage