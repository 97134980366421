// import React, { useState } from 'react';
// import LOGO from '../../assets/logo_01.svg';
// import "./style.css";
// import ArrowLetsDo from '../../assets/LetsDoArrow.svg';
// import Menu from '../../assets/images/Menu.svg';

// function Header() {
//   const [isNavOpen, setIsNavOpen] = useState(false);

//   // Toggle the navigation menu
//   const toggleNav = () => {
//     setIsNavOpen(!isNavOpen);
//   };

//   // Close the navigation menu when a menu item is clicked
//   const closeNav = () => {
//     setIsNavOpen(false);
//   };

//   return (
//     <>
//       <div className="headerLayout">
//         <div className="container">
//           <nav className="navbar navbar-expand-xl">
//             <div className="container-fluid">
//               <a className="navbar-brand" href="#">
//                 <img src={LOGO} alt="" />
//               </a>
//               <button
//                 className="navbar-toggler"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#navbarSupportedContent"
//                 aria-controls="navbarSupportedContent"
//                 aria-expanded={isNavOpen}
//                 aria-label="Toggle navigation"
//                 onClick={toggleNav}
//               >
//                 <img src={Menu} alt="" />
//               </button>
//               <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
//                 <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                 </ul>
//                 <div className='navbar-menu me-auto mb-2 mb-lg-0'>
//                   <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                     <li className="nav-item">
//                       <a className="nav-link active" aria-current="page" href="#" onClick={closeNav}>Home</a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="nav-link" href="#about-us" onClick={closeNav}>About Us</a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="nav-link" href="#services" onClick={closeNav}>Services</a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="nav-link" href="#" onClick={closeNav}>Our Work</a>
//                     </li>
//                   </ul>
//                 </div>

//                 <form className="d-flex" role="search">
//                   <a href='#contact-us' className="letsDoBtn PassiePeepsBtn" onClick={closeNav}>Lets Do it <span className='circle'><img src={ArrowLetsDo} id='ArrowLetsDo1' className="ArrowLetsDo" alt="" /></span></a>
//                 </form>
//               </div>

//             </div>
//           </nav>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Header;


import React from 'react'
import LOGO from '../../assets/logo_01.svg'
import "./style.css";
import ArrowLetsDo from '../../assets/LetsDoArrow.svg'
import Menu from '../../assets/images/Menu.svg'
import CustomModal from './Modals/CustomModal';



function Header() {


  return (
    <>
        
        <div className="headerLayout">
            <div className="container" >
                <nav className="navbar navbar-expand-xl ">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src={LOGO} alt="" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span className="navbar-toggler-icon"></span> */}
                            <img src={Menu} alt="" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            </ul>
                            <div className='navbar-menu me-auto mb-2 mb-lg-0'>
                                <ul className="navbar-nav  me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about-us">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#services">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#MaintenanceModal">Our Work</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <form className="d-flex " role="search">
                                <a href='#contact-us' className="letsDoBtn PassiePeepsBtn">Lets Do it <span className='circle'><img src={ArrowLetsDo} id='ArrowLetsDo1' className="ArrowLetsDo" alt="" /></span></a>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </>
  )
}

export default Header