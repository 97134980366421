import React from 'react'
import "./style.css";
import CloseIcon from "../../../assets/images/closeIcon.svg"

function CustomModal(props) {
  return (
    // id="CustomModal"
    <>
        <div class="modal fade" id={props.id}  tabindex="-1"   aria-labelledby="CustomModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="CustomModalLabel">{props.title}</h1>
                    <img src={CloseIcon} width={20} data-bs-dismiss="modal" aria-label="Close" alt="" />
                    
                </div>
                <div class="modal-body">
                    {props.body}
                </div>
                <div class="modal-footer">
                    {/* <button type="button" class="btn btn-sm btn-dark" data-bs-dismiss="modal">Close</button> */}
                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CustomModal