import React from "react";
import { motion } from "framer-motion";

import "./marquee.css";

import company1 from '../../../assets/images/companies/company1.svg';
import company2 from '../../../assets/images/companies/company2.svg';
import company3 from '../../../assets/images/companies/company3.svg';
import company4 from '../../../assets/images/companies/company4.svg';
import company5 from '../../../assets/images/companies/company5.svg';
import company6 from '../../../assets/images/companies/company6.svg';

const marqueeVariants = {
  animate: {
    x: [0, -1035],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 10,
        ease: "linear",
      },
    },
  },
};

const Marquee = () => {
  return (
    <div>
      <div id='marquee-company'></div>
      <div className="marquee">
        <motion.div
          className="track"
          variants={marqueeVariants}
          animate="animate"
        >
          {/* <h1>
            Let's Work Together. Let's Work Together. Let's Work Together. Let's
            Work Together. Let's Work Together. Let's Work Together. Let's Work
            Together
          </h1> */}

          <div style={{display:'flex', gap:'100px'}}>
            <img src={company1} alt="Company 1" />
            <img src={company2} alt="Company 2" />
            <img src={company3} alt="Company 3" />
            <img src={company4} alt="Company 4" />
            <img src={company5} alt="Company 5" />
            <img src={company6} alt="Company 6" />

            <img src={company1} alt="Company 1" />
            <img src={company2} alt="Company 2" />
            <img src={company3} alt="Company 3" />
            <img src={company4} alt="Company 4" />
            <img src={company5} alt="Company 5" />
            <img src={company6} alt="Company 6" />

            <img src={company1} alt="Company 1" />
            <img src={company2} alt="Company 2" />
            <img src={company3} alt="Company 3" />
            <img src={company4} alt="Company 4" />
            <img src={company5} alt="Company 5" />
            <img src={company6} alt="Company 6" />

            <img src={company1} alt="Company 1" />
            <img src={company2} alt="Company 2" />
            <img src={company3} alt="Company 3" />
            <img src={company4} alt="Company 4" />
            <img src={company5} alt="Company 5" />
            <img src={company6} alt="Company 6" />
          </div>
          
        </motion.div>
      </div>
    </div>
  );
};

export default Marquee;
