import React from 'react'
import './style.css'
import pen from '../../../assets/PenheroPage.svg'
import Arrow from '../../../assets/ArrowHeroPage.svg'
import FramerMotionExampleImage from '../../../assets/framerMotionExample.png'
import company1 from '../../../assets/images/companies/company1.svg'
import company2 from '../../../assets/images/companies/company2.svg'
import company3 from '../../../assets/images/companies/company3.svg'
import company4 from '../../../assets/images/companies/company4.svg'
import company5 from '../../../assets/images/companies/company5.svg'
import company6 from '../../../assets/images/companies/company6.svg'

import { motion, useScroll, useTransform } from "framer-motion";
import { AnimationBody, ImageContainer } from "./animeStyle";
import $ from 'jquery';
import { ClientHandleAnimation } from './Animations/ClientHandleAnimation'
import { ProjectsCompletedAnimation } from './Animations/ProjectsCompletedAnimation'

function HeroSession(){
    const { scrollYProgress } = useScroll();
    const x1 = useTransform(scrollYProgress, [0, 1], [10, -1000]);

    function CustomBadge(props){
        return(
            <>
                <span className='CustomBadge'>
                    {props.roles}
                </span>
            </>
        );
    }


    function CustomBadge1({children}){
        return(
            <>
                <span className='CustomBadge1'>
                    {children}
                </span>
            </>
        );
    }


    setTimeout(function(){

    $('.counters').each(function () {
        var $this = $(this),
            countTo = $this.attr('data-count');

        function formatIndianNumber(number) {
            var parts = number.toString().split('.');
            var integerPart = parts[0];
            var decimalPart = parts[1] ? '.' + parts[1] : '';

            var lastThree = integerPart.slice(-3);
            var otherParts = integerPart.slice(0, -3);

            if (otherParts !== '') {
                lastThree = ',' + lastThree;
            }

            var result = '';
            while (otherParts.length > 2) {
                result = ',' + otherParts.slice(-2) + result;
                otherParts = otherParts.slice(0, -2);
            }

            result = otherParts + result + lastThree;
            return result + decimalPart;
        }

        $({
            countNum: $this.text()
        }).animate({
            countNum: countTo
        }, {
            duration: 500,
            easing: 'linear',
            step: function () {
                $this.text(formatIndianNumber(Math.floor(this.countNum)));
            },
            complete: function () {
                $this.text(formatIndianNumber(this.countNum));
            }
        });
    });
    },100)




    function ClientHandleFunc(){
        return(
            <div className='clientHandle desktop'>
                <div className="counters_layout counters-style " style={{display:'flex'}}><div className="counters" data-count="150"></div>+</div>
                <span className='removeLetterSpaceing'>Client Team Expertise</span>
            </div>
        )
    }

    function ProjectsCompletedFunc(){
        return(
            <>
                <div className='projectsCompleted desktop'>
                    <div className="counters_layout counters-style " style={{display:'flex'}}><div className="counters" data-count="100"></div>+</div>
                    <span className='removeLetterSpaceing'>Projects Completed</span>
                </div>
            </>
        )
    }

    function PhoneHeroSessionPops(){
        return(
            <>
                <div className='PhoneHeroSessionPops'>
                    <div className='clientHandlePhone'>
                        <div className="counters_layout counters-style " style={{display:'flex'}}><div className="counters" data-count="150"></div>+</div>
                        <span className='removeLetterSpaceing'>Client Team Expertise</span>
                    </div>

                    <div className='projectsCompletedPhone'>
                        <div className="counters_layout counters-style " style={{display:'flex'}}><div className="counters" data-count="100"></div>+</div>
                        <span className='removeLetterSpaceing'>Projects Completed</span>
                    </div>
                </div>

            </>
        );
    }

    function HeroParagraphContent(){
        return(
            <>
                <div className='paragraphContent'>
                    <span style={{color:'#E7FF3C'}}>Passion-driven, purpose-driven.</span><br /> We (Peeps) helps you connect with your audience through compelling visuals and engaging content. Because it's time to stand out and be remembered.
                </div>
            </>
        );
    }




    return(
        <>
            <div className="container">
                <div className="heroSessionMainLayout">
                    <div className="row">
                        {/* <div className="col-md"></div> */}
                        <div className="col-md-12">

                            <PhoneHeroSessionPops/>

                            <div className='heroSessionMain mt-5'>
                                <div className='Herolabel py-3'>
                                    <CustomBadge1>
                                        Design & Content Production for <b style={{color:'#E7FF3C'}}>Fast Growth Companies</b>
                                    </CustomBadge1>
                                    {/* <span>Design & Content Production for <b style={{color:'#E7FF3C'}}>Fast Growth Companies</b></span> */}
                                    {/* <div style={{display:'inline-block'}}>
                                        <CustomBadge roles="UI/UX"/>
                                        <CustomBadge roles="UI/UX"/>
                                        <CustomBadge roles="UI/UX"/>
                                    </div> */}
                                </div>

                                

                                <div className='HeroPageMainBodyLayout'>
                                    <div className='HeroPageMainBodyText'>
                                
                                        {/* <ClientHandleAnimation /> */}
                                        <h1 style={{position:'relative'}}>We're Not Just <ClientHandleFunc/></h1>
                                        <h1>Creative, We're Your</h1>
                                        <h1 style={{position:'relative'}}><ProjectsCompletedFunc/><div style={{color:"#E7FF3C"}}>Brand's Visual Voice.</div></h1>
                                        <img className='penSk1' src={pen} alt="" />
                                    </div>

                                    <div className='HeroParagraphContentPhone'>
                                        <HeroParagraphContent/>
                                    </div>
                                    {/* <ProjectsCompletedAnimation/> */}
                                    
                                    <div className='actionBtn'>
                                        <a href="#contact-us" className='CustomGreenBtn hireUs '>Hire Us</a>
                                        <a href='#' className='CustomBlackBtn ourwork ' data-bs-toggle="modal" data-bs-target="#MaintenanceModal">Our Work</a>
                                    </div>


                                    {/* Arrow mark */}
                                    <div className='arrowMark'>
                                        <img src={Arrow} alt="" />
                                        <HeroParagraphContent/>
                                    </div>

                                    

                                    {/* client handle */}



                                    {/* Projects Completed */}

                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md"></div> */}
                    </div>
                </div>
            </div>


            {/* <div className="marquee_label_layout">
                <AnimationBody>
                    <ImageContainer style={{ x: x1 }}>
                        <img src={company1} className='framerImage' alt="" />
                        <img src={company2} className='framerImage' alt="" />
                        <img src={company3} className='framerImage' alt="" />
                        <img src={company4} className='framerImage' alt="" />
                        <img src={company5} className='framerImage' alt="" />
                        <img src={company6} className='framerImage' alt="" />

                        <img src={company1} className='framerImage' alt="" />
                        <img src={company2} className='framerImage' alt="" />
                        <img src={company3} className='framerImage' alt="" />
                        <img src={company4} className='framerImage' alt="" />
                        <img src={company5} className='framerImage' alt="" />
                        <img src={company6} className='framerImage' alt="" />

                        <img src={company1} className='framerImage' alt="" />
                        <img src={company2} className='framerImage' alt="" />
                        <img src={company3} className='framerImage' alt="" />
                        <img src={company4} className='framerImage' alt="" />
                        <img src={company5} className='framerImage' alt="" />
                        <img src={company6} className='framerImage' alt="" />

                        <img src={company1} className='framerImage' alt="" />
                        <img src={company2} className='framerImage' alt="" />
                        <img src={company3} className='framerImage' alt="" />
                        <img src={company4} className='framerImage' alt="" />
                        <img src={company5} className='framerImage' alt="" />
                        <img src={company6} className='framerImage' alt="" />
                    </ImageContainer>
                </AnimationBody>
            </div> */}



            {/* <div className="marquee_label_layout">
                <AnimationBody>
                    <Title style={{ x: x1 }}>
                    UI/UX <span className="wodo_yellow">•</span> LOGOS{" "}
                    <span className="wodo_yellow">•</span> WEBSITES{" "}
                    <span className="wodo_yellow">•</span> BRAND STRATEGY{" "}
                    <span className="wodo_yellow">•</span> CREATIVE CONTENT{" "}
                    <span className="wodo_yellow">•</span> UI/UX{" "}
                    <span className="wodo_yellow">•</span> LOGOS{" "}
                    <span className="wodo_yellow">•</span> WEBSITES{" "}
                    <span className="wodo_yellow">•</span> BRAND STRATEGY{" "}
                    <span className="wodo_yellow">•</span> CREATIVE CONTENT
                    
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    </Title>
                </AnimationBody>
            </div> */}

            {/* <div className='heroSessionBackground'>
                <img src={curtainLeft} alt="" />
                <img src={curtainRight} alt="" />
            </div> */}

        </>
    );

}

export default HeroSession